<template>
    <div>
        <b-form-group class="font-size-h6" :label="$t('NOTIFICATIONS.TEMPLATES.EDIT.SUBJECT')">
            <st-input-text
                v-model="subjectVal"
                name="subject"
            />
        </b-form-group>

        <st-richtext-editor v-model="messageVal"/>
    </div>
</template>

<script>
import StRichtextEditor from '../../../shared/components/StRichtextEditor.vue';
export default {
    components: { StRichtextEditor },
    name: 'EmailNotificationTemplate',
    props: {
        subject: {
            type: String,
            required: true,
        },
        message: {
            type: String,
            default: '',
        },
    },
    computed: {
        subjectVal: {
            get() {
                return this.subject;
            },
            set(val) {
                this.$emit('subject', val);
            },
        },
        messageVal: {
            get() {
                return this.message;
            },
            set(val) {
                this.$emit('message', val);
            },
        },
    },

}
</script>